<template>
  <main>
    <div class="form">
      <ui-input label="收货人:" name="receiver" v-model="address.receiver"></ui-input>
      <ui-input
        label="收货电话:"
        name="contactTel"
        v-model="address.contactTel"
      ></ui-input>
      <ui-select
        label="所在地区"
        title="所在地区"
        name="address"
        v-model="address.address"
        :multiple="true"
      ></ui-select>
      <ui-input
        label="详细地址:"
        name="receiveAddress"
        v-model="address.receiveAddress"
      ></ui-input>
      <div class="switch-item">
        <label for="isDefault">
          <div class="form-label">设置默认</div>
          <input
            class="mui-switch mui-switch-anim"
            v-model="address.isDefault"
            type="checkbox"
          />
        </label>
      </div>
      <div class="save-area">
        <button @click="save">保存</button>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import axios from "@/api/axios";
import UiInput from "@/components/UI/Input";
import UiSelect from "@/components/UI/Select";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import { useRouter, useRoute } from "vue-router";

export interface Iaddress {
  receiver: string;
  contactTel: string;
  address: string;
  receiveAddress: string;
  isDefault: boolean;
  province: string;
  country: string;
  city: string;
}
export default defineComponent({
  name: "addAddress",
  components: {
    UiInput,
    UiSelect,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.query.id || "";

    const address = ref<Iaddress>({
      receiver: "",
      contactTel: "",
      address: "",
      receiveAddress: "",
      isDefault: false,
      province: "",
      country: "",
      city: "",
    });
    if (id) {
      axios
        .get("/M/User/AddAddress/" + id)
        .then((res) => {
          const data = res.data.obj.address;
          const province = data.province || "";
          const city = data.city || "";
          const country = data.country || "";
          data.isDefault == 1?data.isDefault = true:data.isDefault=false
          address.value = { ...data, address: province + " " + city + " " + country };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function back() {
      router.go(-1);
    }

    function save() {
      
     
      const data = qs.stringify({
        id,
        receiver: address.value.receiver,
        contactTel: address.value.contactTel,
        receiveAddress: address.value.receiveAddress,
        isdefault: address.value.isDefault ? "1" : "0",
        province: (address.value.address && address.value.address.split(" ")[0]) || "",
        country: (address.value.address && address.value.address.split(" ")[2]) || "",
        city: (address.value.address && address.value.address.split(" ")[1]) || "",
      });
      console.log(address.value);
      axios
        .post("/M/Server/DoSaveAddress", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                back();
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      address,
      save,
    };
  },
});
</script>

<style lang="scss" scoped>
.save-area {
  width: 100%;
  height: 1.2rem;
  position: relative;
  top: 1.333rem;
}
.save-area button {
  width: 100%;
  height: 1.2rem;
  background-color: #d64b4b;
  box-shadow: 0px 0.133rem 0.267rem 0px rgba(0, 0, 0, 0.3);
  border-radius: 0.1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 0.4rem;
}
.form-item {
  padding-left: 2.4rem;
}
.switch-item {
  padding-left: 1.76rem;
  position: relative;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: right;
  padding-bottom: 1px;
}

.form-label {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: left;
  color: #444444;
  font-size: 0.373rem;
  padding: 0 0.267rem;
}

.mui-switch {
  width: 52px;
  height: 31px;
  position: relative;
  border: 1px solid #dfdfdf;
  background-color: #fdfdfd;
  box-shadow: #dfdfdf 0 0 0 0 inset;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-clip: content-box;
  display: inline-block;
  -webkit-appearance: none;
  user-select: none;
  outline: none;
}
.mui-switch:before {
  content: "";
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.mui-switch:checked {
  border-color: #64bd63;
  box-shadow: #64bd63 0 0 0 16px inset;
  background-color: #64bd63;
}
.mui-switch:checked:before {
  left: 21px;
}
.mui-switch.mui-switch-animbg {
  transition: background-color ease 0.4s;
}
.mui-switch.mui-switch-animbg:before {
  transition: left 0.3s;
}
.mui-switch.mui-switch-animbg:checked {
  box-shadow: #dfdfdf 0 0 0 0 inset;
  background-color: #64bd63;
  transition: border-color 0.4s, background-color ease 0.4s;
}
.mui-switch.mui-switch-animbg:checked:before {
  transition: left 0.3s;
}
.mui-switch.mui-switch-anim {
  transition: border cubic-bezier(0, 0, 0, 1) 0.4s,
    box-shadow cubic-bezier(0, 0, 0, 1) 0.4s;
}
.mui-switch.mui-switch-anim:before {
  transition: left 0.3s;
}
.mui-switch.mui-switch-anim:checked {
  box-shadow: #64bd63 0 0 0 16px inset;
  background-color: #64bd63;
  transition: border ease 0.4s, box-shadow ease 0.4s, background-color ease 1.2s;
}
.mui-switch.mui-switch-anim:checked:before {
  transition: left 0.3s;
}
</style>
